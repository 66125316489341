import toNumber from 'lodash/toNumber';
import { getCoordinates } from '@emobg/web-utils';
import { parking as PARKING_ICON } from '@emobg/web-components';

export const toLocationPrediction = item => ({
  place_id: item.uuid,
  description: item.name,
  structured_formatting: {
    main_text: item.name,
    secondary_text: item.name !== item.display_address ? `| ${item.display_address}` : '',
  },
  icon: PARKING_ICON,
  location: {
    ...getCoordinates(item.gps_lat, item.gps_lng),
  },
});

/**
 * Transform a Prediction returned by UiLocationInput to a Place object
 * @param {{ value: string, description: string, location: { lat: Function<number>, lng: Function<number> } }} item
 * @returns {{address: string, lng: number, uuid, lat: number}}
 */
export const toPredictionPlace = item => ({
  ...getCoordinates(toNumber(item?.location?.lat()), toNumber(item?.location?.lng())),
  uuid: item.value,
  address: item?.description || '',
});
