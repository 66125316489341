<script>
import { defineComponent } from 'vue';

import { useFavouriteLocationsList } from '../../composables/useFavouriteLocationsList';

export default defineComponent({
  setup(_, { emit }) {
    const {
      selectedFavouriteLocation,
      favouriteLocationsList,
      getFavouriteLocationIcon,
      toFavouriteLocationMapPlace,
    } = useFavouriteLocationsList();

    const onSelectFavouriteLocation = (favouriteLocation) => {
      if (!favouriteLocation.disabled) {
        selectedFavouriteLocation.value = favouriteLocation;

        emit('on:select-map-place', toFavouriteLocationMapPlace(favouriteLocation.value));
      }
    };

    const onOpenFavouriteLocations = () => {
      emit('on:open-favourite-locations-modal');
    };

    return {
      favouriteLocationsList,
      selectedFavouriteLocation,
      onSelectFavouriteLocation,
      onOpenFavouriteLocations,
      getFavouriteLocationIcon,
    };
  },
});
</script>

<template>
  <ui-select
    key="favourite-select"
    :value="selectedFavouriteLocation && selectedFavouriteLocation.label"
    :placeholder="$t('map.favourite_select_placeholder')"
    :size="SIZES.large"
    class="GeocompleteSelect w-100 mb-2"
    name="favourite"
    data-test-id="favourites-select"
  >
    <div slot="content">
      <div
        v-for="item in favouriteLocationsList"
        :key="item.value"
        class="p-2 d-flex justify-content-between emobg-background-color-ground-lighter-hover cursor-pointer"
      >
        <div
          :class="['d-flex align-items-center w-100', { disabled: item.disabled }]"
          @click="() => onSelectFavouriteLocation(item)"
        >
          <ui-icon
            :icon="getFavouriteLocationIcon(item.type)"
            :size="ICONS_SIZES.small"
            data-test-id="favourite_type-icon"
            class="d-flex mr-2"
          />
          {{ item.label }}
        </div>
        <ui-button
          :face="FACES.text"
          :size="SIZES.small"
          :color="GRAYSCALE.ink"
          square
          data-test-id="edit_favourites_location-button"
          @clickbutton="onOpenFavouriteLocations"
        >
          <ui-icon
            :icon="ICONS.edit"
            :size="ICONS_SIZES.xSmall"
          />
        </ui-button>
      </div>
    </div>
  </ui-select>
</template>
