import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import reject from 'lodash/reject';
import values from 'lodash/values';

import { computed, ref } from 'vue';

import { getCoordinates } from '@emobg/web-utils';

import { currentFavouriteLocations } from '@/stores/FavouriteLocations/FavouriteLocationsMapper';

import { FAVOURITE_LOCATION_DATA } from '../constants/favouriteConfig.const';

export const useFavouriteLocationsList = () => {
  const selectedFavouriteLocation = ref(null);
  const favouriteLocationsRef = computed(currentFavouriteLocations);

  const getFavouriteLocationLabel = type => FAVOURITE_LOCATION_DATA[type]?.label || '';
  const getFavouriteLocationIcon = type => FAVOURITE_LOCATION_DATA[type]?.icon || '';

  const favouriteLocationsList = computed(() => map(favouriteLocationsRef.value, (location, type) => {
    const hasAddress = !isEmpty(location.address_street);
    const address = hasAddress
      ? location.address_street
      : getFavouriteLocationLabel(type);

    return {
      value: location.uuid,
      label: address,
      type,
      disabled: !hasAddress,
      ...getCoordinates(toNumber(location.gps_lat), toNumber(location.gps_lng)),
    };
  }));

  const validFavouriteLocationsList = computed(() => reject(favouriteLocationsList.value, ['value']));

  const toFavouriteLocationMapPlace = favouriteLocationUuid => {
    const favouriteLocation = find(values(favouriteLocationsRef.value), [
      'uuid',
      favouriteLocationUuid,
    ]);

    return {
      uuid: favouriteLocation.uuid,
      address: favouriteLocation?.address_street || '',
      ...getCoordinates(toNumber(favouriteLocation.gps_lat), toNumber(favouriteLocation.gps_lng)),
    };
  };

  const toFavouriteLocationPrediction = item => ({
    place_id: item.value,
    description: item.label,
    structured_formatting: {
      main_text: getFavouriteLocationLabel(item.type),
      secondary_text: item.label,
    },
    icon: getFavouriteLocationIcon(item.type),
    fixed_prediction: true,
    location: {
      lat: item.lat,
      lng: item.lng,
    },
  });

  return {
    selectedFavouriteLocation,
    favouriteLocationsList,
    validFavouriteLocationsList,
    getFavouriteLocationLabel,
    getFavouriteLocationIcon,
    toFavouriteLocationMapPlace,
    toFavouriteLocationPrediction,
  };
};
