<script>
import { defineComponent } from 'vue';

import { useOperatorLocationsList } from '../../composables/useOperatorLocationsList';

export default defineComponent({
  setup(_, { emit }) {
    const {
      selectedOperatorLocationUuid,
      operatorLocationsList,
      toOperatorLocationMapPlace,
    } = useOperatorLocationsList();

    const selectLocation = (operatorLocationUuid) => {
      selectedOperatorLocationUuid.value = operatorLocationUuid;

      emit('on:select-map-place', toOperatorLocationMapPlace(operatorLocationUuid));
    };

    return {
      selectedOperatorLocationUuid,
      operatorLocationsList,
      selectLocation,
    };
  },
});
</script>

<template>
  <ui-select
    :value.prop="selectedOperatorLocationUuid"
    data-test-id="search_station"
    :placeholder="$t('map.select_station_placeholder')"
    :size="SIZES.large"
    :options.prop="operatorLocationsList"
    :searchbox="{ threshold: 10 }"
    :show-limit="20"
    class="GeocompleteSelect w-100 mb-2"
    @selectoption="({ detail: locationUuid }) => selectLocation(locationUuid)"
  />
</template>
