<script>
import isEqual from 'lodash/isEqual';

export default {
  name: 'BookingTypeSelectorComponent',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsOpened: false,
    };
  },
  methods: {
    isEqual,
    optionClicked(option) {
      this.$emit('optionClicked', option);
      this.optionsOpened = false;
    },
  },
};
</script>

<template>
  <div class="BookingTypeSelector">
    <ui-dropdown
      :placement="PLACEMENTS.bottomStart"
      class="BookingTypeSelector__dropdown"
    >
      <div
        slot="trigger"
        class="BookingTypeSelector__trigger mr-auto cursor-pointer"
      >
        <ui-icon
          :icon="active.icon"
          :size="ICONS_SIZES.xLarge"
          class="BookingTypeSelector__icon"
          data-test-id="booking-types-active-icon"
        />
      </div>
      <div slot="content">
        <div
          v-for="(option, index) in options"
          :key="index"
          :data-test-id="`booking-types-option-${index}`"
          :class="[
            'BookingTypeSelector__option d-flex p-3 cursor-pointer',
            {
              'BookingTypeSelector__option--isActive': isEqual(active, option)
            }]"
          @click="optionClicked(option)"
        >
          <ui-icon
            :data-test-id="`booking-types-option-${index}-icon`"
            :icon="option.icon"
            :size="ICONS_SIZES.xLarge"
            class="BookingTypeSelector__icon"
          />
          <div class="ml-4 flex-fill">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <h2
                :data-test-id="`booking-types-option-${index}-title`"
                class="emobg-font-default"
              >
                {{ option.title }}
              </h2>
              <ui-icon
                v-if="isEqual(active, option)"
                :size="ICONS_SIZES.small"
                :icon="ICONS.check"
                :color="COLORS.success"
              />
            </div>
            <p
              :data-test-id="`booking-types-option-${index}-text`"
              class="emobg-font-small emobg-color-ink-light"
            >
              {{ option.text }}
            </p>
          </div>
        </div>
      </div>
    </ui-dropdown>
  </div>
</template>
