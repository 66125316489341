<template>
  <div class="d-flex flex-column">
    <ui-button-segments
      :value="selectedSearchOption"
      :options.prop="switcherOptions"
      :color="COLORS.primary"
      :size="SIZES.large"
      class="mb-3"
      data-test-id="search_type-toggle"
      @clickbuttonsegment="({ detail }) => selectedSearchOption = detail"
    />

    <OperatorLocationsList
      v-if="selectedSearchOption === searchOptions.station"
      @on:select-map-place="onSelectMapPlace"
    />

    <FavouriteLocationsList
      v-if="selectedSearchOption === searchOptions.favouriteLocations"
      @on:select-map-place="onSelectMapPlace"
      @on:open-favourite-locations-modal="openFavouriteLocationsModal"
    />

    <SearchAddressesList
      v-if="selectedSearchOption === searchOptions.address"
      :current-position="currentPosition"
      @on:center-user-position="$emit('center-user-position')"
      @on:select-map-place="onSelectMapPlace"
    />
  </div>
</template>

<script>
import size from 'lodash/size';
import values from 'lodash/values';
import map from 'lodash/map';
import get from 'lodash/get';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import { GRAYSCALE } from '@emobg/web-components';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';

import { operatorLocations } from '@/stores/OperatorLocations/OperatorLocationsMapper';
import { currentFavouriteLocations } from '@/stores/FavouriteLocations/FavouriteLocationsMapper';
import { getCurrentCity } from '@/stores/City/CityMapper.js';
import { getLocationsCollection } from '@/stores/Locations/LocationsMapper';
import { getCompanyData } from '@/stores/Company/CompanyMapper';
import { userData } from '@/stores/User/UserData/UserDataMapper';

import { SEGMENT_EVENTS } from '@/vue/constants';
import { useSegment } from '@/composable/Segment/segment';
import { SEARCH_OPTIONS_VALUES } from './constants/options.const';
import { ICONS } from './constants/icons.const';
import { FAVOURITE_LOCATION_DATA } from './constants/favouriteConfig.const';

import OperatorLocationsList from './components/OperatorLocationsList/OperatorLocationsList';
import FavouriteLocationsList from './components/FavouriteLocationsList/FavouriteLocationsList';
import SearchAddressesList from './components/SearchAddressesList/SearchAddressesList';

export default {
  name: 'Geocomplete',

  components: {
    OperatorLocationsList,
    FavouriteLocationsList,
    SearchAddressesList,
  },

  props: {
    currentPosition: {
      type: Boolean,
      default: false,
    },
    preferedLocations: {
      type: Boolean,
      default: false,
    },
    hasSwitcher: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackSegment: trackSegmentEvent } = useSegment();

    return { trackSegmentEvent };
  },

  data() {
    return {
      places: {},
      google: googleMapsLoader.getInstance(),
      selectedSearchOption: SEARCH_OPTIONS_VALUES.address,
      selectedFavourite: null,
    };
  },

  computed: {
    currentFavouriteLocations,
    getCurrentCity,
    getLocationsCollection,
    getCompanyData,
    operatorLocations,
    userData,

    filledPreferredLocations() {
      return filter(values(this.currentFavouriteLocations), 'address_street');
    },
    knownPlaces() {
      return concat(values(this.filledPreferredLocations), this.stations);
    },
    defaultPredictions() {
      let predictions = [];
      predictions = map(
        this.filledPreferredLocations,
        prediction => {
          const preferredDescription = this.$t(FAVOURITE_LOCATION_DATA[get(prediction, 'type', 'custom')].label);
          const addressStreet = get(prediction, 'address_street', '');

          let description = `<b>${preferredDescription}</b>`;

          if (addressStreet.trim()) {
            description = `${description} <span class="emobg-color-${GRAYSCALE.inkLight}">${addressStreet}</span>`;
          }

          const icon = ICONS[get(prediction, 'type')];

          return {
            ...prediction,
            description,
            icon,
          };
        },
      );

      if (get(this.userData, 'company.dedicated_fleet_cs_operator')) {
        predictions = concat(predictions, this.stations);
      }

      predictions = concat(predictions, values(this.sanitizedLocations));
      return predictions;
    },
    sanitizedLocations() {
      return map(this.getLocationsCollection,
        location => (
          {
            ...location,
            value: location.address || location.name,
            description: location.name || location.description,
            label: location.name || location.address,
            icon: mapMarkerSelector.parkingIcon(),
          }
        ));
    },
    stations() {
      return map(this.operatorLocations,
        location => (
          {
            ...location,
            value: location.uuid,
            label: location.name || location.address,
          }
        ));
    },
    switcherOptions() {
      const options = [
        {
          label: this.$t('map.toggle_search_type.address_option'),
          value: SEARCH_OPTIONS_VALUES.address,
          condition: true,
        },
        {
          label: this.$t('map.toggle_search_type.station_option'),
          value: SEARCH_OPTIONS_VALUES.station,
          condition: Boolean(size(this.operatorLocations)) && get(this, 'getCompanyData.company_fleets.dedicated'),
        },
        {
          label: this.$t('map.toggle_search_type.favourite_option'),
          value: SEARCH_OPTIONS_VALUES.favouriteLocations,
          condition: this.preferedLocations,
        },
      ];
      return filter(options, 'condition');
    },
  },

  watch: {
    places: {
      handler(value = {}) {
        if (!isEmpty(value.address)) {
          this.emitPlacePrediction(value);
        }
      },
      deep: true,
    },
  },

  created() {
    this.favouriteLocationData = FAVOURITE_LOCATION_DATA;
    this.searchOptions = SEARCH_OPTIONS_VALUES;
  },

  methods: {
    size,
    getPlaceData(place) {
      let station;
      if (isString(place)) {
        station = this.stations.find(opStation => opStation.name === place || opStation.address === place) || {};
      }
      return isEmpty(station)
        ? place
        : station;
    },
    getPlaceCoordinates(place) {
      return {
        lat: toNumber(get(place, 'location.lat', place.gps_lat)),
        lng: toNumber(get(place, 'location.lng', place.gps_lng)),
      };
    },
    emitPlacePrediction(value, isDisabled = false) {
      if (isDisabled) {
        return;
      }

      const place = isString(value)
        ? find(this.knownPlaces, ['uuid', value])
        : this.getPlaceData(value);

      const coordinates = this.getPlaceCoordinates(place);

      if (!isNumber(coordinates.lat) || !isNumber(coordinates.lng)) {
        return;
      }

      this.$emit('input-geocomplete-place', {
        ...coordinates,
        placeResult: {
          uuid: get(place, 'uuid'),
          address: get(place, 'address'),
          location: { ...coordinates },
        },
      });

      this.trackSearchTypeChange(place);
    },

    onSelectMapPlace({
      uuid, address, lat, lng,
    }) {
      this.$emit('input-geocomplete-place', {
        lat,
        lng,
        placeResult: {
          uuid,
          address,
          location: { lat, lng },
        },
      });

      this.trackSearchTypeChange({ address });
    },

    openFavouriteLocationsModal() {
      this.$emit('open-preferred-location', {});
      this.trackSegmentEvent({
        name: SEGMENT_EVENTS.OPEN_PREFERRED_LOCATIONS,
      });
    },

    trackSearchTypeChange(place) {
      const placeName = place.address || place.description || place.name;
      if (!isEmpty(placeName)) {
        this.trackSegmentEvent({
          name: SEGMENT_EVENTS.CHOOSE_NEW_LOCATION_SEARCH,
          data: {
            new_value: placeName,
            search_type: this.selectedSearchOption,
            ff_value: this.$featureFlag.flags.toggleSearchLocationTypes,
          },
        });
      }
    },
  },

};

</script>
