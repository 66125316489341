import map from 'lodash/map';
import find from 'lodash/find';
import toNumber from 'lodash/toNumber';

import { computed, ref } from 'vue';
import { getCoordinates } from '@emobg/web-utils';

import { parking as PARKING_ICON } from '@emobg/web-components';

import { operatorLocations } from '@/stores/OperatorLocations/OperatorLocationsMapper';

export const useOperatorLocationsList = () => {
  const selectedOperatorLocationUuid = ref(null);
  const operatorLocationsRef = computed(operatorLocations);

  const operatorLocationsList = computed(() => map(operatorLocationsRef.value, location => ({
    ...getCoordinates(toNumber(location?.gps_lat), toNumber(location?.gps_lng)),
    value: location.uuid,
    label: location.name || location.address,
  })));

  const toOperatorLocationMapPlace = operatorLocationUuid => {
    const location = find(operatorLocationsList.value, ['value', operatorLocationUuid]);

    return {
      uuid: operatorLocationUuid,
      address: location?.address || '',
      lat: location?.lat,
      lng: location?.lng,
    };
  };

  const toOperatorLocationPrediction = item => ({
    place_id: item.value,
    description: item.label,
    structured_formatting: {
      main_text: '',
      secondary_text: item.label,
    },
    icon: PARKING_ICON,
    location: {
      lat: item.lat,
      lng: item.lng,
    },
  });

  return {
    selectedOperatorLocationUuid,
    operatorLocationsList,
    toOperatorLocationMapPlace,
    toOperatorLocationPrediction,
  };
};
