<template>
  <article
    v-openVehicleDetails="{
      element: slideToggleClass,
      callback: clickMarker,
      disable: expanded
    }"
    :data-test-id="`vehicle-${vehicle.license_plate}`"
    class="Vehicle p-3 mb-3 emobg-background-color-white emobg-shadow-s"
    @click="onClickVehicle"
  >
    <!-- Image about vehicle -->
    <figure class="Vehicle_imageWrapper">
      <img
        :src="vehicleImage"
        alt="Vehicle image"
        width="175px"
        class="Vehicle_image mb-2"
      >
    </figure>

    <!-- Info about booking -->
    <div
      class="Vehicle_dataWrapper"
      :class="{
        'Vehicle_dataWrapper--reorder mt-2': isUnpricedCompany
      }"
    >
      <!-- Info about vehicle -->
      <ModelComponent
        :is-electric="isElectric"
        :model="vehicleTitle"
      />

      <!-- HOME PAGE -->
      <PriceComponent
        v-if="!isUnpricedCompany"
        :tariff="vehicleData.tariff"
        :price-per-day="isLongDistance"
        :mileage-symbol="getVisitedMileageSymbol"
      />

      <BatteryInfoComponent
        v-if="isElectric"
        :critical-level="getValue(vehicleData, 'electric_details.low_battery_level', null)"
        :current-level="getValue(vehicleData, 'electric_details.power_battery_level', null)"
        class="my-1"
      />
      <span
        v-if="isBookingList && vehicleData.tariff && isUnpricedCompany"
        class="emobg-font-default mr-3 my-1 emobg-color-primary"
      >
        {{ showHideDetailsLabel }}
      </span>

      <!-- MY BOOKINGS -->
      <template v-if="isMyBookings">
        <!-- Vehicle details -->
        <div
          :class="[
            `${ isIntervention ? 'emobg-color-ink emobg-font-large' : 'emobg-color-ink-lighter' }`,
          ]"
        >
          <div class="d-flex flex-column align-items-start">
            <LicensePlateComponent
              v-if="vehicle.license_plate"
              class="emobg-color-ink my-1"
            >
              {{ vehicle.license_plate }}
            </LicensePlateComponent>
            <span
              v-if="isIntervention"
              class="my-1"
            >
              {{ `${$t('views.my_bookings.vehicle_color')}: ${vehicle.color}` }}
            </span>
          </div>
        </div>

        <!-- Intervention labels -->
        <template v-if="isIntervention">
          <div class="d-flex flex-wrap emobg-font-small my-1 emobg-color-ink-lightest">
            <ui-badge
              :color="COLORS.success"
              class="px-3"
              data-test-id="is_intervention-badge"
            >
              {{ $t('new_booking.booking_list.vehicle.intervention') }}
            </ui-badge>
            <ui-badge
              v-if="size(booking.tags)"
              :color="COLORS.danger"
              class="px-3 ml-2"
              data-test-id="intervention_type-badge"
            >
              {{ head(booking.tags) }}
            </ui-badge>
          </div>
        </template>
        <template v-else>
          <!-- Insurance protection when is not dedicated fleet -->
          <div
            v-if="!isDedicatedFleet(getValue(booking, 'cs_booking_use_case.fleet', ''))"
            class="d-flex align-items-center emobg-color-ink-lightest emobg-font-small my-1"
          >
            <span>
              {{ $t('new_booking.booking_list.vehicle.insurance_coverage') }}:
            </span>
            <ui-badge
              class="ml-2"
              data-test-id="insurance_type-badge"
            >
              {{ insuranceType }}
            </ui-badge>
          </div>

          <!-- Carpooling usage -->
          <ui-badge
            v-if="getValue(this, 'booking.is_carpooling', false)"
            data-test-id="is_carpooling-badge"
            class="my-2 emobg-font-small emobg-color-ink"
          >
            {{ $t('views.carpooling.carpooling_label_in_my_bookings') }}
          </ui-badge>

          <ui-badge
            v-if="getValue(booking, 'isBookingRequest', false) && !isNonConnected"
            :color="COLORS.warning"
            class="ml-2 emobg-font-small emobg-color-ink px-3 my-1"
            data-test-id="is_preeboking-badge"
          >
            {{ $t('views.my_bookings.pre_booking_pending_badge') }}
          </ui-badge>

          <AlertComponent
            v-if="isNonConnected"
            :type="ALERT_TYPES.warning"
            class="py-2"
            data-test-id="is_non_connected_alert-alert"
          >
            {{ $t('MyBookings.Vehicle.alerts.non_connected_vehicle') }}
          </AlertComponent>
        </template>
      </template>
    </div>

    <!-- Action button -->
    <div class="Vehicle__bookingWrapper d-flex justify-content-start justify-content-xl-end">
      <!-- Home page -->
      <template v-if="isBookingList && hasPermissionToBook">
        <ui-button
          v-bind="fetchButtonSpecs()"
          :disabled="(!canBook || isBookButtonLoading) && !isBtnLoading"
          :loading="isBtnLoading"
          :size="SIZES.large"
          class="mb-3"
          data-test-id="book_car-navigate_booking_summary"
          @clickbutton.stop="canBook && selectVehicle()"
        >
          {{ strActionButtonBookVehicle }}
        </ui-button>
      </template>

      <!-- My Bookings page -->
      <template v-if="isMyBookings">
        <EditBookingDropdown
          v-if="showActionsDropdown"
          :booking="booking"
          :booking-uuid="booking.uuid"
          :allows-one-way="isOneWayAllowed"
          :allows-carpooling="operatorHasCarpooling"
          :is-carpooling="getValue(booking, 'is_carpooling', false)"
          :free-seats="getValue(booking, 'free_seats', 0)"
          :is-non-connected="isNonConnected"
          :is-booking-request="getValue(booking, 'isBookingRequest', false)"
          :is-insurance-upgrade-availaible="isInsuranceUpgradeAvailaible"
          :is-additional-driver-enabled="isAdditionalDriverEnabled"
          :is-additional-passengers-enabled="isAdditionalPassengersEnabled"
          :is-editable="canEditBooking"
          :is-cancelable="canCancelBooking"
        />
      </template>
    </div>

    <!-- Booking alert when is geofence or vehicle is being used -->
    <BookingAlert
      v-if="booking"
      :booking="booking"
      :operator="getValue(booking, 'csOperator')"
      class="w-100 my-2"
    />

    <!-- Additional drivers & Passengers -->
    <section
      v-if="hasAdditionalPeople"
      class="row w-100 my-2"
      @click.stop
    >
      <div class="col-8 col-xl-6">
        <!-- Additional driver -->
        <template v-if="hasAdditionalDrivers">
          <!-- When I'm the owner and there is another drivers -->
          <div
            v-if="isBookingMadeByMe"
            :class="[
              'd-flex flex-column',
              {
                'mb-3': hasPassengers
              }
            ]"
          >
            <p class="mb-1 emobg-font-small emobg-font-weight-bold">
              {{ $t('views.my_bookings.additional_driver_second_driver_fullname', { count: additionalDriversCount }) }}
            </p>
            <div>
              <RemovableBadgeComponent
                v-for="(driver, index) in additionalDrivers"
                :key="`driver_added-${index}`"
                :color="COLORS.primary"
                :data-test-id="`added_driver_${index}`"
                :editable="false"
                class="mb-2 mr-2 d-inline-flex"
              >
                {{ driver.email || driver }}
              </RemovableBadgeComponent>
            </div>
          </div>

          <!-- When I'm not the owner and there is another driver -->
          <div
            v-if="!isBookingMadeByMe"
            :class="[
              'd-flex flex-column',
              {
                'mb-3': hasPassengers
              }
            ]"
          >
            <p class="mb-1 emobg-font-small emobg-font-weight-bold">
              {{ $t('views.my_bookings.additional_driver_main_driver_fullname') }}
            </p>

            <ui-badge :color="COLORS.primary">
              {{ additionalDriverMainUser }}
            </ui-badge>
          </div>
        </template>

        <!-- Additional passengers -->
        <div
          v-if="hasPassengers"
          class="d-flex flex-column"
          @click.stop
        >
          <p class="mb-1 emobg-font-small emobg-font-weight-bold">
            {{ $t('views.my_bookings.additional_passengers_count', { count: passengersCount }) }}
          </p>

          <div class="mt-2">
            <RemovableBadgeComponent
              v-for="(passenger, index) in passengers"
              :key="`passenger_added-${index}`"
              :data-test-id="`passenger_added-${index}`"
              :color="webAppColors.informative"
              :editable="false"
              class="mr-2"
            >
              {{ passenger }}
            </RemovableBadgeComponent>
          </div>
        </div>
      </div>
    </section>

    <!-- Links -->
    <div
      v-if="(isBookingList && !isUnpricedCompany) || isMyBookings"
      class="Vehicle__links my-2"
    >
      <section class="d-flex justify-content-between emobg-border-bottom-1 emobg-border-color-ground">
        <header class="d-flex align-items-center pb-1 emobg-font-default emobg-font-weight-bold emobg-color-ink-light">
          {{ $t('new_booking.booking_list.vehicle.availability_header') }}
        </header>
        <div class="pb-1">
          <!-- Show the link to go to parking-->
          <ui-button
            v-if="isMyBookings && !getValue(booking, 'isBookingRequest', false)"
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
            :size="SIZES.small"
            @clickbutton="goToParking"
          >
            {{ $t('new_booking.booking_list.vehicle.go_to_parking') }}
          </ui-button>

          <ui-button
            v-if="isMyBookings"
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
            :size="SIZES.small"
            @clickbutton.stop="exportToIcs"
          >
            {{ $t('new_booking.booking_list.vehicle.add_to_calendar') }}
          </ui-button>

          <!-- Show or hide details in Home / My bookings page -->
          <ui-button
            v-if="areDetailsVisible"
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
            :size="SIZES.small"
          >
            {{ showHideDetailsLabel }}
            <ui-icon
              :color="COLORS.primary"
              :icon="details ? ICONS.arrowUp : ICONS.arrowDown"
              class="ml-2"
            />
          </ui-button>
        </div>
      </section>
    </div>

    <!-- Alert text for non authorized location (b. on behalf) -->
    <template v-if="showBookingOnBehalfAlert">
      <div class="my-2 row emobg-font-small">
        <ui-icon
          :color="COLORS.danger"
          :icon="ICONS.alertAloneFull"
          :size="ICONS_SIZES.small"
          class="ml-2"
        />
        <span class="emobg-font-default ml-2">
          {{ bookingOnBehalfErrorMessage }}
        </span>
      </div>
    </template>

    <!-- Timeline occupation -->
    <template v-if="vehicleData.occupation">
      <VehicleTimelineComponent
        v-if="timelineLoaded"
        v-bind="bookedTime"
        :time-blocks-busy="occupation.bookings"
        class="mw-100 mt-2"
        data-test-id="availability_timeline"
      />
      <VehicleTimelinePlaceholder
        v-else
        has-gradient
      />
    </template>

    <!-- Extra info about the vehicle and his tariff -->
    <div
      v-show="expanded"
      :class="[
        'Vehicle_details mt-4 mb-3',
        slideToggleClass
      ]"
    >
      <!-- Vehicle details -->
      <SpecificationsComponent
        v-if="hasDetails"
        :class="{
          'mb-4': vehicleData.equipment || isTariffInfoVisible
        }"
        :category="vehicleData.category"
        :fuel="vehicleData.fuel"
        :is-electric="isElectric"
        :license-plate="vehicleData.license_plate"
        :seats="vehicleData.seats"
        :transmission="vehicleData.transmission"
      />

      <div
        :class="{
          'mb-4': vehicleData.equipment || isTariffInfoVisible,
        }"
      >
        <template v-if="isMyBookings">
          <div
            v-if="size(costAllocationsMapped)"
            class="Vehicle_details_header emobg-font-default my-3 pb-1"
          >
            {{ $t('new_booking.booking_list.vehicle.cost_allocation') }}
          </div>

          <div
            v-for="costAllocation in costAllocationsMapped"
            :key="costAllocation.name"
          >
            <span class="emobg-font-weight-bold">{{ costAllocation.name }}: </span>
            <span class="emobg-font-small emobg-color-ink-light">{{ costAllocation.value }}</span>
          </div>

          <template v-if="comment">
            <div class="Vehicle_details_header emobg-font-default my-3 pb-1">
              {{ $t('new_booking.booking_list.vehicle.comments') }}
            </div>
            <span>
              {{ comment }}
            </span>
          </template>
        </template>
      </div>

      <!-- More specs -->
      <ExtrasComponent
        :extras="vehicleData.equipment"
        :class="{
          'mb-4': isTariffInfoVisible,
        }"
      />

      <!-- Pricing information -->
      <TariffInformationComponent
        v-if="isTariffInfoVisible"
        :tariff="vehicleData.tariff"
        :is-electric="isElectric"
      />
    </div>
  </article>
</template>

<script>
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import head from 'lodash/head';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';

import moment from 'moment';
import { aclService } from '@emobg/access-utils';
import {
  DATE_FORMAT, DISTANCE_UNIT, getValue, reformatDateTime, TIME_ZONE,
} from '@emobg/web-utils';
// Config
import { external } from '@emobg/web-api-client';
import { mapGetters } from 'vuex';
// Utils
import { isLongDistance } from '@/helpers/booking/bookingHelpers';
import ida from '@/utils/ImportDynamicAssets';
import { EV_ICON_PROPS } from '@/utils/ev-helpers';

// Components
import BookingAlert from '@/components/VehicleCard/components/BookingAlert/BookingAlertComponent';
import AlertComponent from '@/components/Alert/AlertComponent';
import VehicleTimelinePlaceholder
  from '@/vue/components/molecules/VehicleTimelinePlaceholder/VehicleTimelinePlaceholder';
import EditBookingDropdown from '@/components/VehicleCard/components/EditBookingDropdown/EditBookingDropdown';
import BatteryInfoComponent from '@/components/VehicleCard/components/BatteryInfo/BatteryInfoComponent';
import SpecificationsComponent from '@/components/VehicleCard/components/Specifications/SpecificationsComponent';
import LicensePlateComponent from '@/components/VehicleCard/components/LicensePlate/LicensePlateComponent';
import VehicleTimelineComponent from '@/components/VehicleCard/components/VehicleTimeline/VehicleTimelineComponent';

import TariffInformationComponent
  from '@/components/VehicleCard/components/TariffInformation/TariffInformationComponent';
import ModelComponent from '@/components/VehicleCard/components/Model/ModelComponent';
import PriceComponent from '@/components/VehicleCard/components/Price/PriceComponent';
import ExtrasComponent from '@/components/VehicleCard/components/Extras/ExtrasComponent';
import RemovableBadgeComponent from '@/components/RemovableBadge/RemovableBadgeComponent';
// Composable
import { useAvailability } from '@/composable/Availability/useAvailability';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import SegmentMixin from '@/mixins/Segment';
import CsOperatorMixin from '@/mixins/CSOperator';

import { getCurrentBookingType } from '@/stores/Booking/stores/BookingType/BookingTypeMapper';

import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';

// Services
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

// Directives
import SlideToggle from '@/directives/SlideToggle';
// Store
import * as CostAllocationModule from '@/vue/stores/BookingSummary/CostAllocations';
import { composeAlgoliaCostAllocationValue } from '@/utils/costAllocationHelpers';
import { nameSpace as UserInsuranceNameSpace } from '@/vue/stores/UserInsurance/UserInsuranceStore';

// Constants
import { SEGMENT_ENUMERATE_VALUES, SEGMENT_EVENTS, SEGMENT_PARAM_NAMES } from '@/vue/constants';
import { USER_STATUS } from '@/constants/userStatus.const';
import { BOOKING_USAGE_STATUS } from '@/constants/bookingUsageStatus.const';

import { CANCEL_CS_LD_OWN_BOOKINGS, CREATE_CS_LD_OWN_BOOKINGS, EDIT_OWN_CS_BOOKINGS } from '@/constants/permissions';
import { isDedicatedFleet } from '@/helpers/booking/fleetHelpers';

import ALERT_TYPES from '@/components/Alert/alertTypes';
import { isPreregistered } from '@/helpers/user/status/userStatusHelper';
import { useSegment } from '@/composable/Segment/segment';
import { useTheme } from '@/composable/Theme/useTheme';
import { webAppColors } from '@/constants/themes';
// Requires
const $ = require('jquery');

// Dynamic icons
const PreBookingImage = ida.icon('/pre_booking_vehicle_image.png');

export default {

  components: {
    BatteryInfoComponent,
    AlertComponent,
    BookingAlert,
    EditBookingDropdown,
    ExtrasComponent,
    LicensePlateComponent,
    ModelComponent,
    PriceComponent,
    SpecificationsComponent,
    TariffInformationComponent,
    VehicleTimelineComponent,
    VehicleTimelinePlaceholder,
    RemovableBadgeComponent,
  },

  directives: {
    openVehicleDetails: SlideToggle,
  },

  mixins: [
    EventHandlerMixin,
    SegmentMixin,
    CsOperatorMixin,
  ],

  props: {
    type: {
      type: String,
      default: 'bookinglist',
    },
    visible: {
      type: Boolean,
    },
    location: {
      type: Object,
      default: () => ({}),
    },
    vehicle: {
      type: Object,
      default: () => ({}),
    },
    mapManager: {
      type: Object,
      default: () => ({}),
    },
    availabilityTime: {
      type: Object,
      default: () => ({}),
    },
    bookedTime: {
      type: Object,
      default: () => ({}),
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
    isInsuranceUpgradeAvailaible: {
      type: Boolean,
      default: false,
    },
    isAdditionalDriverEnabled: {
      type: Boolean,
      default: false,
    },
    isAdditionalPassengersEnabled: {
      type: Boolean,
      default: false,
    },
    isBookingRequest: {
      type: Boolean,
      default: false,
    },
    isIntervention: {
      type: Boolean,
      default: false,
    },
    additionalDrivers: {
      type: Array,
      default: () => [],
    },
    passengers: {
      type: Array,
      default: () => [],
    },
    comment: {
      type: String,
      default: undefined,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    csOperator: {
      type: Object,
      default: () => ({}),
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    isBookButtonLoading: {
      type: Boolean,
      default: false,
    },
    onClickBookButton: {
      type: Function,
      default: () => ({}),
    },
  },

  setup() {
    const { getSelectedEmployee, isBookingOnBehalf } = useAvailability();
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return { getSelectedEmployee, isBookingOnBehalf, trackSegment, fetchButtonSpecs, webAppColors };
  },

  data() {
    return {
      timelineLoaded: false,
      slideToggleClass: `js-slideToggle-${(Math.random() * 10000).toFixed(0)}`,
      details: false,
      dataDetails: null,
      vehicleData: this.vehicle,
      isBtnLoading: false,
    };
  },

  computed: {
    getCurrentBookingType,
    ...mapGetters(CostAllocationModule.nameSpace, [
      'costAllocations',
      'getCostAllocationByUuid',
      'getCostAllocationChildByUuid',
    ]),
    ...mapGetters(UserInsuranceNameSpace, ['getUserInsurance']),
    isTariffInfoVisible() {
      return !this.isMyBookings && this.vehicleData.tariff && !this.isUnpricedCompany;
    },
    isLongDistance() {
      return isLongDistance(getValue(this, 'getCurrentBookingType.code', null));
    },
    canBook() {
      const vehiclesAvailables = getValue(this, 'location.show_vehicle_availability', true)
        ? getValue(this, 'vehicle.occupation.available', false)
        : true;

      const employeeOpenFleetStatus = getValue(this.getSelectedEmployee, 'open_fleet_status', USER_STATUS.deactivated);
      const employeeCanBookLocationType = getValue(this.location, 'open', true)
        ? toLower(employeeOpenFleetStatus) === toLower(USER_STATUS.activated)
        : true;

      const bookingOnBehalfConditions = this.isBookingOnBehalf
        ? !getValue(this.location, 'user_documents.hasInvalidDocuments', false) && employeeCanBookLocationType
        : true;

      const userStatusConditions = this.userData.getStatus() !== USER_STATUS.blocked;

      return vehiclesAvailables && bookingOnBehalfConditions && userStatusConditions;
    },

    hasPermissionToBook() {
      return !this.isBookingOnBehalf
        ? aclService.hasPermissions(CREATE_CS_LD_OWN_BOOKINGS)
        : true;
    },

    isUserMainDriver() {
      return getValue(this, 'user.uuid', null) === getValue(this, 'userData.uuid', null);
    },

    canEditBooking() {
      return this.isUserMainDriver && aclService.hasPermissions(EDIT_OWN_CS_BOOKINGS);
    },

    canCancelBooking() {
      return this.isUserMainDriver && this.hasBookingStarted && aclService.hasPermissions(CANCEL_CS_LD_OWN_BOOKINGS);
    },

    hasBookingStarted() {
      if (this.booking && this.booking.start) {
        const now = reformatDateTime(moment.utc().format(DATE_FORMAT.filter), DATE_FORMAT.filter, TIME_ZONE.default);
        const isBookingStarted = moment(this.booking.start).isValid() && getValue(this.booking, 'usage_status_key', null) === BOOKING_USAGE_STATUS.started;
        return moment(now).isAfter(this.booking.start) || isBookingStarted;
      }
      return false;
    },

    employeeSelectedStatus() {
      return getValue(this, 'getSelectedEmployee.user_status', USER_STATUS.deactivated);
    },

    showBookingOnBehalfAlert() {
      return this.isBookingOnBehalf && (
        this.employeeSelectedHasInvalidDocuments || isPreregistered(this.employeeSelectedStatus)
      );
    },

    bookingOnBehalfErrorMessage() {
      return isPreregistered(this.employeeSelectedStatus)
        ? this.$t('new_booking.booking_list.booking_on_behalf.employee_is_pre_registered.vehicle.info')
        : this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.vehicle.info');
    },

    showActionsDropdown() {
      return this.canEditBooking || this.canCancelBooking;
    },

    isBooking() {
      return this.type.toLowerCase() === 'booking';
    },

    isBookingList() {
      return this.type.toLowerCase() === 'bookinglist';
    },

    isMyBookings() {
      return this.type.toLowerCase() === 'mybookings';
    },

    occupation() {
      return { ...this.vehicle.occupation, ...this.availabilityTime };
    },

    insuranceType() {
      return getValue(this, 'booking.hasInsuranceUpsell', null)
        ? this.$t('new_booking.booking_list.vehicle.basic_insurance_label')
        : this.$t('new_booking.booking_list.vehicle.full_insurance_label');
    },

    price() {
      const { state } = this.$store;
      return getValue(state, 'Booking.bookingSummary.price.total', 0);
    },
    vehiclePricingShowDetailsLabel() {
      const defaultShowLabel = this.$t('new_booking.booking_list.vehicle.show_pricing_and_vehicle_details');
      const variantShowLabel = this.$t('new_booking.booking_list.vehicle.availability_details');
      return isEmpty(this.booking) ? defaultShowLabel : variantShowLabel;
    },
    vehiclePricingHideDetailsLabel() {
      const defaultHideLabel = this.$t('new_booking.booking_list.vehicle.hide_pricing_and_vehicle_details');
      const variantHideLabel = this.$t('new_booking.booking_list.vehicle.hide_availability_details');
      return isEmpty(this.booking) ? defaultHideLabel : variantHideLabel;
    },
    showHideDetailsLabel() {
      return this.details
        ? this.vehiclePricingHideDetailsLabel
        : this.vehiclePricingShowDetailsLabel;
    },
    areDetailsVisible() {
      return (this.isBookingList && !this.isUnpricedCompany) || (this.isMyBookings && !getValue(this.booking, 'isBookingRequest', false));
    },
    isBookingMadeByMe() {
      if (!this.isMyBookings) {
        return false;
      }

      return !isEmpty(this.booking)
        && getValue(this.booking, 'user.uuid', null) === getValue(this, 'userData.uuid', null);
    },

    employeeSelectedHasInvalidDocuments() {
      return getValue(this.location, 'user_documents.hasInvalidDocuments', false);
    },

    isUnpricedCompany() {
      // When a company is unpriced, we should not show
      // any type of price. By default, if this $prop
      // does not exist, we will show the price.
      return getValue(this, 'vehicle.tariff.is_unpriced_tariff', false);
    },

    strHourlyPrice() {
      return hourlyPrice => this.$t('new_booking.booking_list.vehicle.details_price_hour', { hourly_price: hourlyPrice });
    },

    strDailyPrice() {
      return dailyPrice => this.$t('new_booking.booking_list.vehicle.details_price_day', { daily_price: dailyPrice });
    },

    vehicleTitle() {
      if (this.isBooking || this.isBookingList) {
        return getValue(this, 'vehicleData.model', null);
      }

      if (this.isNonConnected) {
        return this.$t('MyBookings.Vehicle.title.non_connected_vehicle');
      }

      if (this.isMyBookings) {
        return this.isBookingRequest
          ? `${this.vehicleData.name}`
          : `${this.vehicleData.brand} ${this.vehicleData.model}`;
      }

      return '';
    },

    additionalDriverMainUser() {
      return isEmpty(this.user) ? '' : `${this.user.first_name} ${this.user.last_name}`;
    },

    strActionButtonBookVehicle() {
      const textOptions = [
        {
          condition: this.isBookingOnBehalf && this.employeeSelectedHasInvalidDocuments,
          translationKey: 'new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.vehicle.action_button',
        },
        {
          condition: !this.canBook && this.employeeSelectedHasInvalidDocuments,
          translationKey: 'new_booking.booking_list.vehicle.not_available',
        },
        {
          condition: true,
          translationKey: 'new_booking.booking_list.vehicle.book_car',
        },
      ];
      const { translationKey } = head(filter(textOptions, 'condition'));

      return this.$t(translationKey);
    },

    batteryLevelLabel() {
      return this.vehicleData.fuel_level
        ? this.$t('new_booking.booking_list.vehicle.battery_level_indicator', {
          battery_level: this.vehicleData.fuel_level,
        })
        : this.$t('new_booking.booking_list.vehicle.battery_level_not_available');
    },

    isNonConnected() {
      return getValue(this, 'booking.is_non_connected', false);
    },

    isOneWayAllowed() {
      const operatorHasOneWay = Boolean(getValue(this, 'csOperator.configuration.has_one_way', false));
      const bookingAllowsOneWay = Boolean(getValue(this, 'booking.one_way_allowed', false));

      return operatorHasOneWay && bookingAllowsOneWay;
    },

    operatorHasCarpooling() {
      return Boolean(getValue(this, 'csOperator.configuration.allow_carpooling', false));
    },

    vehicleImage() {
      return this.isBookingRequest
        ? PreBookingImage
        : this.vehicle.image;
    },

    mileageUnit() {
      return getValue(this.getVisitedCsOperator, 'configuration.mileage_unit', DISTANCE_UNIT.kilometers);
    },

    isElectric() {
      return Boolean(getValue(this, 'vehicleData.electric_details', false));
    },

    hasDetails() {
      return some([
        getValue(this, 'vehicleData.specifications', null),
        getValue(this, 'vehicleData.category', null),
        getValue(this, 'vehicleData.licensePlate', null),
      ]);
    },

    costAllocationsMapped() {
      const bookingCostAllocations = getValue(this, 'booking.cost_allocations', null);
      if (size(bookingCostAllocations)) {
        return bookingCostAllocations;
      }

      const companyCostAllocations = getValue(this, 'booking.company_cost_allocations', null);
      let costAllocationsMapped = [];

      if (size(this.costAllocations)
        && size(companyCostAllocations)
      ) {
        costAllocationsMapped = map(
          companyCostAllocations,
          costAllocation => composeAlgoliaCostAllocationValue(costAllocation, this.costAllocations),
        );
      }
      return costAllocationsMapped;
    },

    additionalDriversCount() {
      return size(this.additionalDrivers);
    },

    passengersCount() {
      return size(this.passengers);
    },

    hasPassengers() {
      return this.passengersCount > 0;
    },

    hasAdditionalDrivers() {
      return this.additionalDriversCount > 0;
    },

    hasAdditionalPeople() {
      return this.hasAdditionalDrivers || this.hasPassengers;
    },

  },

  watch: {
    visible(value) {
      if (value === true && this.timelineLoaded === false) {
        this.resolveTimelineComponent();
      }
    },
    details(value) {
      // // SEGMENT: "Open Details"
      if (value) {
        this.vehicleDetailsShowed();
      }
    },
  },

  async beforeMount() {
    this.EV_ICON_PROPS = EV_ICON_PROPS;
  },

  mounted() {
    if (this.visible) {
      this.resolveTimelineComponent();
    }
  },

  created() {
    this.ALERT_TYPES = ALERT_TYPES;
  },

  methods: {
    getValue,
    size,
    head,
    fetchCSOperator,
    isDedicatedFleet,

    clickMarker() {
      const isVisible = !!$(`.${this.slideToggleClass}:visible`).length;

      if (isVisible) {
        const google = googleMapsLoader.getInstance();

        const list = this.mapManager.getMarkerList();

        const myMarker
            = list[`location_${this.location.original_uuid}`]
            || list[`location_${this.location.uuid}`]
            || (this.booking && list[`location_${this.booking.uuid}`]);

        if (myMarker && this.mapManager.getLastMarkerSelected() !== myMarker) {
          this.mapManager.showMarker(myMarker);
          google.maps.event.trigger(myMarker, 'click');
        }
      }
    },

    selectVehicle() {
      this.isBtnLoading = true;
      this.onClickBookButton(true);
      this.eventHandler.$emit(this.events.GO_BOOKING_CONFIRM, {
        vehicle: this.vehicle,
        location: this.location,
        toggleBtnLoading: () => {
          this.isBtnLoading = !this.isBtnLoading;
          this.onClickBookButton(false);
        },
      });
    },

    resolveTimelineComponent() {
      this.timelineLoaded = true;
    },

    goToParking(e) {
      e.stopPropagation();
      window.open(`https://www.google.com/maps/?q=${this.location.gps_lat},${this.location.gps_lng}`, '_blank');
    },

    exportToIcs() {
      const filename = 'booking.ics';
      const content = this.createIcsFile(this.booking);
      const blob = new Blob([content], { type: 'data:text/calendar;charset=utf8' });

      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.trackSegment({
        name: SEGMENT_EVENTS.ADD_TO_CALENDAR_CLICK,
        data: {
          start_time: this.booking.start,
          end_time: this.booking.end,
          fleet: getValue(this, 'booking.cs_booking_use_case.fleet', null),
          profile: getValue(this, 'booking.cs_booking_use_case.profile', null),
          booking_type: getValue(this, 'booking.cs_booking_use_case.booking_type', null),
        },
      });
    },

    createIcsFile(booking) {
      const startDate = this.transformDateToISO(booking.start);
      const endDate = this.transformDateToISO(booking.end);
      const vehicle = getValue(booking, 'isBookingRequest', null)
        ? getValue(booking, 'vehicle_category.name', null)
        : `${booking.vehicle.brand}-${booking.vehicle.model}-${booking.vehicle.license_plate}`;
      const header = 'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Ubeeqo//NONSGML v1.0//EN\nBEGIN:VEVENT\n';
      const footer = `SUMMARY:Booking:${vehicle}\nEND:VEVENT\nEND:VCALENDAR`;
      const body = `DTSTART:${startDate}\nDTEND:${endDate}\nLOCATION:${booking.location.address}\n`;
      return `${header}${body}${footer}`;
    },

    transformDateToISO(date) {
      let dateToIso = moment.utc(date).toISOString();
      dateToIso = dateToIso.replace(/([-_.:])|(000Z)/g, '');
      return dateToIso;
    },

    vehicleDetailsShowed() {
      this.trackSegment({
        name: SEGMENT_EVENTS.VEHICLE_DETAILS_VIEWED,
        data: {
          [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: this.isLongDistance
            ? SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.LONG_DISTANCE
            : SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.CAR_SHARING,
          [SEGMENT_PARAM_NAMES.CAR_MODEL]: getValue(this.vehicle, 'model', null),
          [SEGMENT_PARAM_NAMES.CAR_CATEGORY]: getValue(this.vehicle, 'category', null),
          [SEGMENT_PARAM_NAMES.AVAILABILITY]: getValue(this.vehicle, 'occupation.available', null),
          [SEGMENT_PARAM_NAMES.VEHICLE_ID]: getValue(this.vehicle, 'uuid', null) || getValue(this.vehicle, 'category', null),
        },
      });
    },

    async onClickVehicle() {
      if (isNull(this.dataDetails) && !this.isBookingRequest) {
        const { model, ...rest }
            = (await external.fleetVehicles.getVehicleDetails(this.vehicle.uuid));
        /*
            Ñapa: We are concating Model and Brand in a computed
            but the endpoint VehicleDetails returns model and brand
            in model key and it appears duplicated
            when me merge the data 😒
           */
        this.dataDetails = rest;

        this.vehicleData = {
          ...this.vehicleData,
          ...this.dataDetails,
        };
      }

      this.details = !this.details;

      this.$emit('click');
    },
  },
};

</script>
