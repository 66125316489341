import { ICONS } from '@emobg/web-components';
import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const FAVOURITE_LOCATION_DATA = {
  custom: {
    label: $t('modal.preferredLocations.custom'),
    icon: ICONS.heart,
  },
  home: {
    label: $t('modal.preferredLocations.home'),
    icon: ICONS.home,
  },
  work: {
    label: $t('modal.preferredLocations.work'),
    icon: ICONS.bold.company,
  },
};
